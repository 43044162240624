<template> 
    <v-layout justify-center class="pt-9" fill-height> 
        <mbs-page-spinner v-if="!DayShift" />  
        <v-card outlined id="print_template" v-else max-width="300">
            <v-layout column  justify-center  v-if="Company" class="grey--text text--darken-2 py-5 pt- mx-0 px-2 text-center" >
                <div class="font-weight-bold not-f5">{{DayShift.company_name}}</div> 
                <div class="font-weight-bold not-f3">Phone: {{Company.company_phone_number}}</div> 
                <div class=" not-f3">Email: {{Company.company_email_address}}</div> 
                <div class=" not-f3">Location Address: {{Company.company_location_address}}</div> 
            </v-layout>
            <v-divider class="mx-3 " ></v-divider>

            <div class="mx-3">
                
                <!-- //DETAILS -->
                <br>
                <div class="text-center">{{sales_type?sales_type:'X-REPORT'}}</div>
                <div>
                    <v-layout row wrap  class="ma-0">
                        <div>Outlet:</div>
                        <v-spacer></v-spacer>
                        <div>{{DayShift.outlet_name}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Operating Date:</div>
                        <v-spacer></v-spacer>
                        <div>{{MBS.date.moment(DayShift.operating_date).format("ddd, Do, MMMM YYYY")}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Tills:</div> 
                        <v-spacer></v-spacer><div v-for="(till,index) in DayShift.joined_tills" :key="index">{{till.name}},</div>
                    </v-layout>
                </div> 
                
                <!-- //Opening --> 
                <v-divider class=" mt-5 mb-3" ></v-divider> 
                <div class="text-center mb-2">OPENING CASH REPORT</div>
                <div>
                    <v-layout row wrap  class="ma-0">
                        <div>Open at :</div> 
                        <v-spacer></v-spacer>
                        <div>{{DayShift.local_created_at_}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Open By :</div> 
                        <v-spacer></v-spacer>
                        <div>{{DayShift.user_name}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>floating Cash:</div> 
                        <v-spacer></v-spacer>
                        <div>MWK {{DayShift.total_floating_cash_}}</div> 
                    </v-layout> 
                </div> 
            
                <!-- //CASHUPS -->
                <v-divider class=" mt-5 mb-3" ></v-divider>
                <div class="text-center mb-2">CASHUPS / REGISTERS</div>
                <div>
                    <div  v-for="(cashup,index) in Joined_Cashups" :key="index" 
                        class="mx- mb-5">
                        <v-layout class="ma-0 ">
                            <div>Teller:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>{{cashup.teller_name}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Till:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>{{cashup.till_name}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Open at:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>{{cashup.local_created_at_}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Closed at:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>{{cashup.close_time_?cashup.close_time_:"..."}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Floating Cash:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK {{cashup.floating_cash_?cashup.floating_cash_:"..."}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Opening Balance:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK {{cashup.opening_cash_?cashup.opening_cash_:"..."}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Cash:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                                <span>{{MBS.actions.money(TOTAL_SOLD(CashCashupItems(cashup.key)))}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>On Account:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                                <span>{{MBS.actions.money(TOTAL_SOLD(OnAccountCashupItems(cashup.key)))}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Total Cash:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                                <span>{{MBS.actions.money(TOTAL_SOLD(CashupOrdersItems(cashup.key)))}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Closing Cash:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK {{cashup.closing_cash_?cashup.closing_cash_:"0.00"}}</div>  
                        </v-layout> 
                    </div> 
                </div>

                <!-- //CLOSING REPORT --> 
                <v-divider class=" mt-5 mb-3" ></v-divider> 
                <div class="text-center mb-2">CLOSING CASH REPORT</div>
                <div>
                    <v-layout row wrap  class="ma-0">
                        <div>Close at :</div> 
                        <v-spacer></v-spacer>
                        <div>{{DayShift.local_created_at_}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Close By :</div> 
                        <v-spacer></v-spacer>
                        <div>{{DayShift.user_name}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Retail:</div> 
                        <v-spacer></v-spacer>
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(RetailDayShiftItems))}}</div>  
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Wholesale:</div> 
                        <v-spacer></v-spacer>
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(WholesaleDayShiftItems))}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Cash:</div> 
                        <v-spacer></v-spacer>
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(CashDayShiftItems))}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>On Account:</div> 
                        <v-spacer></v-spacer>
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(OnAccountDayShiftItems))}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Invoice Payments:</div> 
                        <v-spacer></v-spacer>
                        <div>MWK {{MBS.actions.money(TotalPayments)}}</div> 
                    </v-layout>
                </div> 

                <!-- //TOTALS --> 
                <v-divider class=" mt-5 mb-3" ></v-divider>  
                <div class="font-weight-bold ">
                    
                    <v-layout  row wrap class="ma-0">
                        <div   >TOTAL SALES:</div> 
                        <v-spacer></v-spacer>  
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(DayShiftSaleOrdersItems))}}</div> 
                    </v-layout>
                    
                    <v-layout   class="ma-0">
                        <div >TOTAL CASH:</div> 
                        <v-spacer></v-spacer> 
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(CashDayShiftItems)+DayShift.total_floating_cash+TotalPayments)}}</div>  
                    </v-layout>
                    <v-layout   class="ma-0">
                        <div >FLOATING CASH:</div> 
                        <v-spacer></v-spacer> 
                        <div>MWK {{DayShift.total_floating_cash_}}</div> 
                    </v-layout>
                    
                    <v-layout   class="ma-0">
                        <div >CASH SALES:</div> 
                        <v-spacer></v-spacer> 
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(CashDayShiftItems))}}</div>  
                    </v-layout>
                    
                    <v-layout    class="ma-0">
                        <div >CLOSING CASH:</div>  
                        <v-spacer></v-spacer>  
                        <div>MWK {{MBS.actions.money(DayShift.total_closing_cash)}}</div> 
                    </v-layout> 
                </div>  
            </div> 
             
             
            <v-divider class="mx-3 mt-9 mb-2"></v-divider> 
            <v-layout column class="text-center" >
                <div class="font-weight-bold not-f3">POWERED BY MBERA SOLUTION</div>
                <div class="font-weight-bold not-f3">{{DATA.APP.ADMIN_PHONE}}</div> 
            </v-layout> 
            <br><br>
        </v-card>
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME  = 'DAY-SHIFT-TEMPLATE-1'
    export default {
        props:['data','sales_type'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true
            } 
        }, 
        mounted(){ 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
            }), 
            DataPayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DataSaleOrders(){
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders 
                return JoinedSaleOrders?JoinedSaleOrders:SaleOrders
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            DayShift(){ 
                let data = this.data 
                return  data
            },
            Company(){ 
                let item = this.DayShift
                return item?item.company:null
            }, 
            Cashups(){ 
                let item = this.DayShift 
                return item?item.cashups:null
            }, 
            Joined_Cashups(){ 
                let item = this.DayShift 
                let cashups = item?item.joined_cashups:null 
                return cashups
            }, 
            
            AllSaleOrders(){ 
                let items = this.$store.getters.getSaleOrders  
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return !item.archive  
                }) 
                let list = [...filtered_items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true))  
                return list
            }, 
            Joined_SaleOrders(){   
                let orders = this.AllSaleOrders 
                let joined = this.MBS.actions.JOIN_SALE_ORDERS(orders)  
                return joined
            },
            DayShiftSaleOrders(){ 
                let sales = this.Joined_SaleOrders
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null 
                if(!day_shift_key||!sales){return null}
                let filtered_items = sales.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })   
                return filtered_items  
            }, 
            DayShiftSaleOrdersItems(){
                let sales = this.DayShiftSaleOrders
                let items = this.ORDER_ITEMS(sales) 
                return items
            },
            RetailDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.RETAIL_ITEMS(items)
                return retail_items 
            },
            WholesaleDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.WHOLESALE_ITEMS(items)
                return retail_items 
            },
            CashDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.CASH_ITEMS(items)
                return retail_items 
            },
            OnAccountDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.ON_ACCOUNT_ITEMS(items)
                return retail_items 
            },
            
            CashupOrders(){
                let orders = this.DayShiftSaleOrders
                return cashup_key =>{
                    if(!orders){return null}
                    return orders.filter(order=>{
                        return order.cashup_key == cashup_key
                    })
                }
            },
            CashupOrdersItems(){
                return (cashup_key)=>{
                    let sales = this.CashupOrders(cashup_key)
                    let items = this.ORDER_ITEMS(sales) 
                    return items 
                }
            },
            RetailCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.RETAIL_ITEMS(items)
                    return retail_items  
                }
            },
            WholesaleCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.WHOLESALE_ITEMS(items)
                    return retail_items  
                }
            },
            CashCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.CASH_ITEMS(items)
                    return retail_items  
                }
            },
            OnAccountCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.ON_ACCOUNT_ITEMS(items)
                    return retail_items  
                }
            },


            TotalClosingCash(){
                let cashups = this.Joined_Cashups
                if(!cashups){return null}
                let total = 0
                cashups.forEach(element => {
                    total = total+this.toNumber(element.closing_cash)
                });
                return total
            },


            ThisPayments(){
                let payments = this.DataPayments
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null
                if(!payments||!day_shift_key){return null}
                let filled_items = payments.filter(item=>{
                    return item.day_shift_key == day_shift_key
                }) 
                return filled_items
            },
            TotalPayments(){
                try { 
                    let items = this.ThisPayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  

            
        },
        methods:{
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    account_type:element.account_type
                                }) 
                            });
                            
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        if(!items){return null}
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity) 
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_SOLD',
                        page:PAGE_NAME, 
                    })
                }  
            },  
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
